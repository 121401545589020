<template>
  <html>
    <head>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>O Boticário</title>
    </head>

    <body>
      <div class="capa-grey">
        <div class="container">
          <div class="row" style="justify-content: center">
            <div class="col-md-12 col-12 col-sm-10 col-lg-4 mt-5">
              <div class="mx-auto bg-light p-3 lpp4 mt-5">
                <div class="border-top-0 py-3 px-3">
                  <h4 class="">
                    Olá,
                    {{
                      hasSpace(accessname)
                        ? accessname.split(" ")[0]
                        : accessname
                    }}<br />
                  </h4>
                  <p class="text-muted text-left small mb-1">
                    Por segurança, digite apenas os 4 primeiros dígitos do seu
                    CPF:
                  </p>
                  <form v-on:submit="dologin" v-on:submit.prevent id="form">
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        aria-label="cpf-cnpj"
                        aria-describedby="basic-addon"
                        class="form-control"
                        v-model="form.accessdoc"
                        :disabled="loading"
                        v-mask="['####']"
                      />
                    </div>
                    <div class="col-md-8" style="padding: 0px">
                      <div
                        v-if="promoCodeErrors.length"
                        style="text-align: left; font-size: 11px"
                      >
                        <b
                          v-for="error in promoCodeErrors"
                          v-bind:key="error"
                          v-bind:class="classMessage"
                          class="form-text"
                          >{{ error }}</b
                        >
                      </div>
                    </div>
                    <button
                      v-if="!loading"
                      type="submit"
                      class="btn btn-lg d-flex my-4 mx-auto text-white"
                      style="background-color: #d71a20"
                    >
                      Prosseguir
                    </button>
                  </form>
                  <div class="mt-3">
                    <ProgressLoading v-bind:loading="loading" />
                  </div>
                </div>
              </div>
              <div class="text-center mt-4">
                <img
                  src="/img/cards.png"
                  alt=""
                  class=""
                  style="width: 200px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  </html>
</template>

<script>
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import ProgressLoading from "../../modules/auth/components/ProgressLoading.vue";
import { ActionGetIPAddress } from "@/utils/helpers";

export default {
  components: {
    ProgressLoading,
  },
  name: "Siscobra",
  directives: { mask },
  mixins: [validationMixin],
  validations: {
    form: {
      accessdoc: { required, minLength: minLength(4) },
    },
  },
  data: () => ({
    themeBackground: null,
    imageBackground: null,
    form: {
      accessdoc: null,
      accesscode: null,
      termsAcepted: false,
    },
    error: {
      alert: false,
      status: null,
      message: null,
    },
    accessname: "",
    loading: false,
    largura: null,
    ipUser: null,
    userSiscobra: false,
  }),
  methods: {
    ...mapActions("auth", [
      "ActionGetMailingFirstName",
      "ActionMailingDoLogin",
      "ActionAuthenticateSiscobra",
    ]),
    async dologin() {
      this.promoCodeErrors.pop();
      this.loading = true;
      this.promoCodeErrors.pop();
      this.error.status = "ERROR";
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.loading = false;
        return;
      } else {
        this.loading = true;
        try {
          //eslint-disable-next-line
          // debugger;
          var result = await this.ActionAuthenticateSiscobra({
            AccessDoc: this.form.accessdoc,
            IP: this.ipUser ? this.ipUser : "",
            OS: navigator.platform,
            DeviceName: process.env.VUE_APP_API_DEVICE,
            DebtorID: this.debtorId,
            UserID: this.userId,
            tag: localStorage.getItem("tag"),
            SiscobraAuth: true
          });

          if (!result.success) {
            this.promoCodeErrors.push(result.data.message);
            this.error.status = "ERROR";
            this.loading = false;
            return;
          }

          this.loading = false;

          this.$router.push({ name: "home", params: { UserID: this.userId } });
        } catch (error) {
          this.promoCodeErrors.push(
            "ocorreu um erro ao autenticar, dados informados inválido"
          );
          this.error.status = "ERROR";
          this.loading = false;
        }
        this.loading = false;
      }
    },

    async getIPAddressUser() {
      let maxAttempts = 10;
      let attempts = 0;

      while (attempts < maxAttempts) {
        try {
          this.ipUser = await ActionGetIPAddress();
          break; // Saia do loop se o IP for obtido com sucesso
        } catch (error) {
          console.log("Erro ao obter o endereço IP. Tentando novamente...");
          attempts++;
          await new Promise((resolve) => setTimeout(resolve, 1000)); // Aguarde 1 segundo antes de tentar novamente
        }
      }

      if (attempts === maxAttempts) {
        console.log(
          "Número máximo de tentativas atingido. Não foi possível obter o endereço IP."
        );
      }
    },

    hasSpace(str) {
      return /\s/.test(str);
    },
  },
  async created() {
    this.largura = window.screen.width;
    await this.getIPAddressUser();
    this.userSiscobra = localStorage.getItem("userSiscobra");
    if (!this.userSiscobra) {
      this.$router.push({ name: "home" });
    }

    this.debtorId = this.$route.params.DebtorID;
    this.userId = this.$route.params.UserID;
    this.accessname = this.$route.params.FullName;
  },
  computed: {
    promoCodeErrors() {
      const errors = [];

      if (!this.$v.form.accessdoc.$dirty) return errors;
      !this.$v.form.accessdoc.required && errors.push("Campo é obrigatório!");
      !this.$v.form.accessdoc.minLength &&
        errors.push("O CPF não está completo!");

      return errors;
    },
    classMessage: function () {
      return {
        "text-danger": this.error.status == "ERROR",
        "text-muted": !this.error.status == "ERROR",
      };
    },
  },
};
</script>